import { Box } from '@mui/material';
import { FC, ReactElement } from 'react';
import GenericDataTable from '../../../../shared/components/GenericDataTable';
import { AdminUserCommentModel } from '../../../../shared/utilities/__generated__/graphql';

const AdminCommentsTable: FC<{ comments: Partial<AdminUserCommentModel>[] }> = ({
   comments,
}): ReactElement => {
   if (!comments || comments.length === 0) {
      return <></>;
   }
   return (
      <>
         <Box sx={{ marginTop: '24px' }}>
            <GenericDataTable
               rows={comments.map((c) => ({
                  id: c.comment + c.createdAt,
                  ...c,
               }))}
               columns={[
                  { field: 'comment', headerName: 'Comment', flex: 3 },
                  { field: 'creator', headerName: 'Created by', flex: 1 },
                  { field: 'createdAt', headerName: 'Created At', flex: 1 },
               ]}
               useRowsAsColumns={false}
            />
         </Box>
      </>
   );
};

export default AdminCommentsTable;

import { Check } from '@mui/icons-material';
import {
   Button,
   Chip,
   CircularProgress,
   MenuItem,
   Select,
   TextField,
   ToggleButton,
   ToggleButtonGroup,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { isMatch } from 'lodash';
import { useSnackbar } from 'notistack';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
   Locales,
   PromptModel,
   TagPrefillType,
   usePromptInAllLocalesQuery,
   useSavePromptMutation,
} from '../../../../shared/utilities/__generated__/graphql';
import { stringToColor } from '../../../../shared/utilities/helpers';
import { buildPrefillTypeChip } from '../AllPrompts/AllPrompts';

export interface PromptEdit {
   id: string;
   locale: string;
   content?: string;
   chatTypes?: string[];
   prefillType?: string;
   constraints?: string;
}

export interface EditablePrompt extends PromptModel {
   edited?: boolean;
}

export const CHAT_TYPES = {
   CHAT: 'CHAT',
   DOUBBLE_MATCH_CHAT: 'DOUBBLE_MATCH_CHAT',
};

const EditPrompt: FC<void> = (): ReactElement => {
   const { id } = useParams();

   const { loading, data, error, refetch } = usePromptInAllLocalesQuery({
      variables: {
         promptId: id as string,
      },
   });

   const { enqueueSnackbar } = useSnackbar();

   const [savePromptMutation] = useSavePromptMutation();
   const [isSaving, setIsSaving] = useState<Locales[]>([]);

   /**
    * Holds the prompt data with their edits to save them
    */
   const [promptsWithEdits, setPromptWithEdits] = useState<EditablePrompt[]>([]);

   useEffect(() => {
      if (data?.promptInAllLocales) {
         setPromptWithEdits(data.promptInAllLocales);
      }
   }, [data]);

   /**
    * Pass in partial prompt to update the state and mark it edited, so you can save it
    */
   const edit = (input: PromptEdit) => {
      const existingPrompt = promptsWithEdits.find(
         (p: any) => p.id === id && p.locale === input.locale,
      );

      const originalPrompt = data?.promptInAllLocales.find(
         (p: any) => p.id === id && p.locale === input.locale,
      );

      if (!originalPrompt || !existingPrompt) {
         return;
      }

      let newPrompt = {
         ...existingPrompt,
         ...input,
      };

      const isEdited =
         !isMatch(newPrompt, originalPrompt) ||
         JSON.stringify(newPrompt.chatTypes?.sort()) !==
            JSON.stringify(originalPrompt.chatTypes?.sort());

      newPrompt = {
         ...newPrompt,
         edited: isEdited,
      };

      setPromptWithEdits(
         promptsWithEdits.map((p: any) => {
            if (p.id === id && p.locale === input.locale) {
               return newPrompt;
            }
            return p;
         }),
      );
   };

   const saveEditedPrompt = async (prompt: EditablePrompt): Promise<PromptModel | undefined> => {
      if (!prompt.locale || !prompt.chatTypes?.length) {
         enqueueSnackbar('Missing either locale or chat types', { variant: 'error' });
         return;
      }
      setIsSaving([...isSaving, prompt.locale]);
      try {
         const { data } = await savePromptMutation({
            variables: {
               input: {
                  id: prompt.id,
                  locale: prompt.locale.toUpperCase() as Locales,
                  content: prompt.content,
                  chatTypes: prompt.chatTypes ?? [],
                  tagPrefillType: prompt.prefillType,
                  gameType: prompt.gameType,
                  constraints: JSON.stringify(undefined),
               },
            },
         });
         return data?.savePrompt;
      } finally {
         setIsSaving([...isSaving.filter((l) => l !== prompt.locale)]);
         refetch();
      }
   };

   if (loading) {
      return <div>Loading...</div>;
   }

   if (error) {
      return (
         <>
            <div>Error loading prompt data: {error.message}</div>
            <div>{error.stack}</div>
         </>
      );
   }

   if (promptsWithEdits.length === 0) {
      return <div>No prompt data available</div>;
   }

   const columns: GridColDef[] = [
      {
         field: 'content',
         headerName: 'Came',
         width: 600,
         renderCell: ({ value, row }) => {
            return (
               <TextField
                  style={{
                     margin: '12px',
                  }}
                  onKeyDown={(e) => {
                     e.stopPropagation();
                  }}
                  id='outlined-basic'
                  label='Enter prompt'
                  variant='outlined'
                  defaultValue={value}
                  multiline={true}
                  fullWidth={true}
                  onChange={(e) => {
                     edit({
                        ...row,
                        content: e.target.value,
                     });
                  }}
               />
            );
         },
      },
      {
         field: 'locale',
         headerName: 'Locale',
         width: 65,
         renderCell: ({ value: locale }) => {
            return (
               <Chip sx={{ backgroundColor: stringToColor(locale) }} color='info' label={locale} />
            );
         },
      },
      {
         field: 'chatTypes',
         headerName: 'Chat types',
         width: 350,
         renderCell: ({ value: types, row }) => {
            return (
               <ToggleButtonGroup>
                  {Object.values(CHAT_TYPES).map((t: string) => {
                     const hasTypeEnabled = types.includes(t);
                     return (
                        <ToggleButton
                           key={t}
                           selected={hasTypeEnabled}
                           color='info'
                           value='all'
                           onClick={() => {
                              edit({
                                 ...row,
                                 chatTypes: hasTypeEnabled
                                    ? types.filter((type: string) => type !== t)
                                    : [...types, t],
                              });
                           }}
                        >
                           {t}
                           {hasTypeEnabled ? <Check /> : undefined}
                        </ToggleButton>
                     );
                  })}
               </ToggleButtonGroup>
            );
         },
      },
      {
         field: 'prefillType',
         headerName: 'Tag Prefill',
         width: 130,
         renderCell: ({ value: type, row }) => {
            return (
               <Select
                  sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                  value={type}
                  onChange={(e) => {
                     edit({
                        ...row,
                        prefillType: e.target.value,
                     });
                  }}
               >
                  {Object.values(TagPrefillType).map((prefillType) => {
                     return (
                        <MenuItem value={prefillType} key={prefillType}>
                           {buildPrefillTypeChip(prefillType)}
                        </MenuItem>
                     );
                  })}
               </Select>
            );
         },
      },
      {
         field: 'gameType',
         headerName: 'Game type',
         width: 230,
         renderCell: ({ value: type }) => {
            return <Chip label={type} sx={{ backgroundColor: stringToColor(type) }} color='info' />;
         },
      },
      {
         field: 'constraints',
         headerName: 'Constraints',
      },
      {
         field: 'edited',
         headerName: 'Save prompt',
         width: 150,
         renderCell: ({ value, row }) => {
            const isSavingRow = isSaving.includes(row.locale);
            return (
               <Button
                  variant='contained'
                  color='primary'
                  title='Save'
                  disabled={!(value ?? false) || isSavingRow}
                  onClick={() => saveEditedPrompt(row)}
               >
                  {isSavingRow ? <CircularProgress size={20} /> : 'Save'}
               </Button>
            );
         },
      },
   ];

   return (
      <div style={{ height: '100%', width: '100%' }}>
         <DataGrid
            rows={promptsWithEdits}
            rowCount={promptsWithEdits.length}
            loading={loading}
            sx={{ height: '90vh' }}
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.locale}
            columns={columns}
            rowHeight={100}
         ></DataGrid>
      </div>
   );
};

export default EditPrompt;

import {
   Box,
   Button,
   Chip,
   FormControl,
   Grid,
   InputLabel,
   MenuItem,
   Select,
   TextField,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
   ChatType,
   GameType,
   usePaginatedPromptsQuery,
} from '../../../../shared/utilities/__generated__/graphql';
import { navigateOrNewTab, stringToColor } from '../../../../shared/utilities/helpers';

export const buildChatTypeChip = (chatType: ChatType) => {
   if (chatType === 'DOUBBLE_MATCH_CHAT') {
      return <Chip label={chatType} color='info' sx={{ backgroundColor: '#e84393' }} />;
   }
   if (chatType === 'CHAT') {
      return <Chip label={chatType} color='info' />;
   }
   return <Chip label={chatType} />;
};

export const buildGameTypeChip = (gameType: GameType) => {
   return <Chip label={gameType} sx={{ backgroundColor: stringToColor(gameType) }} color='info' />;
};

export const buildPrefillTypeChip = (prefillType: string) => {
   if (prefillType === 'MATCH') {
      return <Chip label='MATCH' sx={{ backgroundColor: '#e84393' }} />;
   }
   if (prefillType === 'FRIEND') {
      return <Chip label='FRIEND' color='info' />;
   }
   return <Chip label={prefillType} color='default' />;
};

const columns: GridColDef[] = [
   { field: 'content', headerName: 'Came', width: 600 },
   {
      field: 'chatTypes',
      headerName: 'Chat types',
      width: 350,
      renderCell: ({ value: types }) => {
         return types?.map((type: ChatType) => buildChatTypeChip(type)) || [];
      },
   },
   {
      field: 'prefillType',
      headerName: 'Tag Prefill',
      width: 130,
      renderCell: ({ value: type }) => {
         return buildPrefillTypeChip(type);
      },
   },
   {
      field: 'gameType',
      headerName: 'Game type',
      width: 230,
      renderCell: ({ value: type }) => {
         return buildGameTypeChip(type);
      },
   },
   {
      field: 'constraints',
      headerName: 'Constraints',
   },
];

function AllPrompts() {
   const [page, setPage] = useState(0);
   const [pageSize, setPageSize] = useState(100);

   const [chatType, setChatType] = useState<string>('');
   const [gameType, setGameType] = useState<string>('');
   const [search, setSearch] = useState<string>('');

   const { loading, data } = usePaginatedPromptsQuery({
      variables: {
         skip: pageSize * page,
         take: pageSize,
         chatType: chatType.length > 0 ? chatType : null,
         gameType: gameType.length > 0 ? gameType : null,
         search: search.length > 0 ? search : null,
      },
   });

   // Some API clients return undefined while loading
   // Following lines are here to prevent `rowCountState` from being undefined during the loading
   const [rowCount, setRowCount] = useState(data?.paginatedPrompts.total || 0);
   useEffect(() => {
      setRowCount((prevRowCountState) =>
         data?.paginatedPrompts.total !== undefined
            ? data?.paginatedPrompts?.total || 0
            : prevRowCountState,
      );
   }, [data?.paginatedPrompts.total, setRowCount]);

   const navigate = useNavigate();

   return (
      <div style={{ height: '100%', width: '100%' }}>
         <Box sx={{ width: 100, height: 20 }} />
         <div style={{ width: '80%' }}>
            <Grid container spacing={2} style={{ justifyContent: 'space-between' }}>
               <Grid item xs={4}>
                  <TextField
                     id='outlined-basic'
                     label='Search...'
                     fullWidth
                     variant='outlined'
                     value={search}
                     onChange={(e) => {
                        setSearch(e.target.value);
                     }}
                  />
               </Grid>
               <Grid item xs={4}>
                  <FormControl fullWidth>
                     <InputLabel>Game Type</InputLabel>
                     <Select
                        value={gameType}
                        label='Game type'
                        onChange={(e) => setGameType(e.target.value as string)}
                     >
                        <MenuItem value={''}>
                           <Chip label='NONE' />
                        </MenuItem>
                        {Object.values(GameType).map((gameType) => {
                           return (
                              <MenuItem value={gameType} key={gameType}>
                                 {buildGameTypeChip(gameType)}
                              </MenuItem>
                           );
                        })}
                     </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={4}>
                  <FormControl fullWidth>
                     <InputLabel>Chat Type</InputLabel>
                     <Select
                        value={chatType}
                        label='Chat type'
                        onChange={(e) => setChatType(e.target.value as string)}
                     >
                        <MenuItem value={''}>
                           <Chip label='NONE' />
                        </MenuItem>
                        {Object.values(ChatType).map((chatType) => {
                           return (
                              <MenuItem value={chatType} key={chatType}>
                                 {buildChatTypeChip(chatType)}
                              </MenuItem>
                           );
                        })}
                     </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={4}>
                  <Button
                     variant='contained'
                     onClick={() => {
                        navigateOrNewTab(`/new-prompt`, navigate, event);
                     }}
                  >
                     Create a new prompt
                  </Button>
               </Grid>
            </Grid>
         </div>
         <Box sx={{ width: 100, height: 12 }} />
         <DataGrid
            rows={data?.paginatedPrompts.items || []}
            rowCount={rowCount}
            loading={loading}
            rowsPerPageOptions={[25, 50, 100]}
            pagination
            page={page}
            pageSize={pageSize}
            sx={{ height: '90vh' }}
            paginationMode='server'
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            columns={columns}
            onRowClick={(params, event) => {
               navigateOrNewTab(`/prompts/${params.id}`, navigate, event);
            }}
            // initialState={initialState}
         />
      </div>
   );
}

export default AllPrompts;

import { Edit } from '@mui/icons-material';
import { Button, Card, CircularProgress, Grid, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { FC, ReactElement, useState } from 'react';
import { useAddCommentToProfileMutation } from '../../../../shared/utilities/__generated__/graphql';

const AdminCommentField: FC<{
   phoneNumber: string;
   onAddedComment: (comment: string) => void;
}> = ({
   phoneNumber,
   onAddedComment,
}: {
   phoneNumber: string;
   onAddedComment: (comment: string) => void;
}): ReactElement => {
   const [comment, setComment] = useState('');
   const [addCommentProfile, { loading }] = useAddCommentToProfileMutation();
   const { enqueueSnackbar } = useSnackbar();
   // Acccess props

   return (
      <Grid container spacing={2}>
         <Grid item xs={12}>
            <Grid item>
               <Card>
                  <TextField
                     fullWidth
                     multiline
                     rows={1}
                     value={comment}
                     onChange={(e) => setComment(e.target.value)}
                     placeholder={`Add a comment to this profile...`}
                  />
               </Card>
            </Grid>
         </Grid>

         <Grid item>
            <Button
               variant='contained'
               color='primary'
               onClick={async () => {
                  try {
                     await addCommentProfile({ variables: { comment, phoneNumber } });
                     enqueueSnackbar(`Commment added`, { variant: 'success' });
                     onAddedComment(comment);
                     setComment('');
                  } catch (e) {
                     enqueueSnackbar(`Could not add comment: ${e}`, { variant: 'error' });
                  }
               }}
            >
               {loading ? <CircularProgress size={20} color='info' /> : 'Add Comment'}
               <Edit />
            </Button>
         </Grid>
      </Grid>
   );
};

export default AdminCommentField;

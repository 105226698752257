import { AttachMoney, Search, Send } from '@mui/icons-material';
import { Box, Button, Chip, Divider, Grid, Tab, Tabs, Typography, styled } from '@mui/material';
import { format, isAfter } from 'date-fns';
import { groupBy } from 'lodash';
import { FC, ReactElement, useState } from 'react';
import { useParams } from 'react-router-dom';
import GenericDataTable from '../../../../shared/components/GenericDataTable';
import { ProfileModel, useGetUserQuery } from '../../../../shared/utilities/__generated__/graphql';
import { calculateAge, formatDateReadable } from '../../../../shared/utilities/helpers';
import ClearReportsButton from '../../../Reports/components/ClearReportsButton';
import ReportsTable from '../../../Reports/components/ReportsTable';
import WarningButton from '../../../Reports/components/WarningButton';
import WarningsTable from '../../../Reports/components/WarningsTable';
import SuspendButton from '../../../Suspension/components/SuspendButton';
import AdminCommentsTable from '../../components/Comments';
import AdminCommentField from '../../components/Comments/AddCommentField';
import DeleteUserButton from '../../components/DeleteUserButton';

import EnablePaywallButton from '../../components/EnablePaywallButton';
import ProfileImages from '../../components/ProfileImages';
import RemoteActionButton from '../../components/RemoteActionButton';
import RemoveFromWaitListButton from '../../components/RemoveFromWaitListButton';
import RemoveUpdateLimitButton from '../../components/RemoveUpdateLimitButton';
import SuspensionsTable from '../../components/SuspensionssTable/SuspensionsTable';
import UserLogsTable from '../../components/UserLogsTable/UserLogsTable';

const NameItem = styled('div')(({ theme }) => ({
   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   ...theme.typography.body2,
   fontWeight: 'bold',
   paddingBottom: theme.spacing(1),
   color: theme.palette.text.secondary,
   display: 'flex',
   alignItems: 'center',
   justifyItems: 'center',
   justifyContent: 'start',
}));
const ValueItem = styled('div')(({ theme }) => ({
   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
   ...theme.typography.body2,
   paddingBottom: theme.spacing(1),
   color: theme.palette.text.secondary,
   maxWidth: '450px',
}));

const NameItemContainer = styled(Grid)(({ theme: _ }) => ({
   display: 'flex',
}));

const ViewUser: FC<void> = (): ReactElement => {
   const { id } = useParams();
   const { loading, data, refetch } = useGetUserQuery({
      variables: {
         userId: id as string,
      },
      fetchPolicy: 'cache-and-network',
   });

   const [tabviewValue, setValue] = useState('Comments');

   const handleTabChange = (_: any, newValue: string) => {
      setValue(newValue);
   };

   if (loading) {
      return <div>Loading...</div>;
   }

   const user = data?.adminGetUser as ProfileModel;

   if (!user) {
      return <div>User not found</div>;
   }

   document.title = user.name + ' - Doubble Admin';

   const _intercomLink = () => {
      const request = {
         predicates: [
            {
               attribute: 'role',
               comparison: 'eq',
               type: 'role',
               value: 'user_role',
            },
            {
               attribute: 'user_id',
               comparison: 'eq',
               type: 'string',
               value: user.id,
            },
         ],
      };

      // base64 encode
      const encodedRequest = btoa(JSON.stringify(request));

      return `https://app.intercom.com/a/apps/r5ejm44g/users/segments/all-users:${encodedRequest}`;
   };

   return (
      <div style={{ height: '100%', width: '100%' }}>
         <Grid container>
            <Grid item xs={6}>
               <Typography variant='h4' mb={2}>
                  {user.phoneNumber} - {user.name} -{' '}
                  <SuspendButton onChange={() => refetch()} user={user} />{' '}
                  <DeleteUserButton user={user} />{' '}
                  <Button
                     startIcon={<Send />}
                     variant='outlined'
                     target='_blank'
                     href={_intercomLink()}
                  >
                     Search in Intercom
                  </Button>{' '}
                  <Button
                     startIcon={<Search />}
                     variant='outlined'
                     href={`/reports?filter-reporter-contains=${user.username}&sort=lastReportedAt,desc`}
                  >
                     See created reports
                  </Button>{' '}
                  <RemoveUpdateLimitButton user={user} /> <RemoteActionButton user={user} />{' '}
                  <RemoveFromWaitListButton user={user} />{' '}
                  {!user.hasPaywall ? <EnablePaywallButton user={user} /> : ''}{' '}
                  <Button
                     startIcon={<AttachMoney />}
                     variant='outlined'
                     target='_blank'
                     href={'https://app.revenuecat.com/customers/7344fc2c/' + user.id}
                  >
                     Show in RevenueCat
                  </Button>{' '}
               </Typography>
               <Box sx={{ width: '100%' }}>
                  <Grid container>
                     <Grid item md={1} xs={12}>
                        <NameItem>Name</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.name}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>Username</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.username}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>Email</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.email}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>Bio</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.bio}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>Gender</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.gender}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>Birthdate</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>
                           {format(new Date(user.birthDate), 'PPP')} ({calculateAge(user.birthDate)}{' '}
                           years old)
                        </ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>School</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.school}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>Occupation</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.occupation}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>City (from profile)</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.city}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>Country</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.country}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>City</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.area}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>Within Supported Region</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.withinSupportedRegion ? 'Yes' : 'No'}</ValueItem>
                     </Grid>

                     <NameItemContainer item md={1} xs={12}>
                        <NameItem>Status</NameItem>
                     </NameItemContainer>
                     <Grid item md={11} xs={12}>
                        <ValueItem>
                           {user.onWaitlist ? (
                              <>
                                 <Chip label={`On waitlist`} color='warning' variant='outlined' />
                              </>
                           ) : user.deletedAt ? (
                              <Chip
                                 label={`Schedulded for deletion at ${formatDateReadable(
                                    user.deletedAt,
                                 )}`}
                                 color='error'
                                 variant='outlined'
                              />
                           ) : user.suspension &&
                             (user.suspension.permanent ||
                                isAfter(new Date(user.suspension.until), new Date())) ? (
                              <Chip
                                 label={`Suspended ${
                                    user.suspension.permanent
                                       ? 'permanently'
                                       : 'until ' + formatDateReadable(user.suspension.until)
                                 }`}
                                 color='error'
                              />
                           ) : user.matchingActive == true ? (
                              <Chip label='Matching enabled' color='success' />
                           ) : (
                              <Chip
                                 label='Matching deactivated'
                                 color='warning'
                                 variant='outlined'
                              />
                           )}
                        </ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>Created at</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{formatDateReadable(user.createdAt)}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>Last active</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{formatDateReadable(user.lastActive)}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>Updated at</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{formatDateReadable(user.updatedAt)}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>App Version</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.appVersion}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>Operating system</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.operatingSystem}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>Device type</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>{user.deviceType}</ValueItem>
                     </Grid>

                     <Grid item md={1} xs={12}>
                        <NameItem>IP</NameItem>
                     </Grid>
                     <Grid item md={11} xs={12}>
                        <ValueItem>
                           {user.ipAddress != 'N/A' ? (
                              <a href={`https://radar.cloudflare.com/ip/${user.ipAddress}`}>
                                 {user.ipAddress}
                              </a>
                           ) : (
                              user.ipAddress
                           )}
                        </ValueItem>
                     </Grid>
                  </Grid>
               </Box>
               <ProfileImages user={user} />
            </Grid>

            <Grid item>
               <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                  <Divider orientation='vertical' flexItem absolute />
               </Box>
            </Grid>

            {/* Tab bar view in right side of screen */}

            <Grid item xs={6}>
               <Box sx={{ width: '100%', typography: 'body1' }}>
                  <Tabs
                     value={tabviewValue}
                     onChange={handleTabChange}
                     textColor='secondary'
                     indicatorColor='secondary'
                     aria-label='secondary tabs example'
                  >
                     <Tab
                        value='Comments'
                        label={`Comments (${user.adminComments?.length ?? 0})`}
                     />
                     <Tab value='Friends' label={`Friends (${user.teamMates?.length ?? 0})`} />
                     <Tab value='Reports' label={`Reports (${user.reports?.length ?? 0})`} />
                     <Tab
                        value='Suspensions'
                        label={`Suspensions (${user.suspensions?.length ?? 0})`}
                     />
                     <Tab value='Warnings' label={`Warnings (${user.warnings?.length ?? 0})`} />
                     <Tab value='Logs' label={`Logs`} />
                  </Tabs>
               </Box>

               {tabviewValue === 'Friends' && (
                  <>
                     <Typography mb={2} mt={2} variant='h4'>
                        {user.teamMates.length} friends
                     </Typography>
                     <GenericDataTable
                        rows={user.teamMates}
                        getCellLink={(params) => `/users/${params.id}`}
                        columns={[
                           {
                              field: 'reports',
                              headerName: 'Reports',
                              flex: 1,
                              renderCell: ({ value }) => {
                                 const reports = Object.keys(groupBy(value, 'message'));

                                 return reports.map((message) => (
                                    <Chip
                                       sx={{ mr: 1 }}
                                       key={message}
                                       label={message}
                                       variant='outlined'
                                    />
                                 ));
                              },
                           },
                        ]}
                     />
                  </>
               )}

               {tabviewValue === 'Suspensions' && (
                  <SuspensionsTable suspensions={user.suspensions!} />
               )}

               {tabviewValue === 'Comments' && (
                  <>
                     <Typography mt={2} mb={2} variant='h4'>
                        Comments
                     </Typography>
                     <AdminCommentField
                        phoneNumber={user.phoneNumber}
                        onAddedComment={async () => {
                           await refetch();
                           window.scrollTo(0, document.body.scrollHeight);
                        }}
                     />
                     <AdminCommentsTable comments={user.adminComments!} />
                  </>
               )}

               {tabviewValue === 'Warnings' && (
                  <>
                     <Typography mb={2} variant='h4'>
                        Warnings{' '}
                        <WarningButton
                           user={user}
                           onChange={async () => {
                              await new Promise((resolve) => setTimeout(resolve, 5000));
                              refetch();
                           }}
                        />
                     </Typography>
                     <WarningsTable warnings={user.warnings!} />
                  </>
               )}

               {tabviewValue === 'Reports' && (
                  <>
                     <Typography mb={2} mt={2} variant='h4'>
                        {user.reports?.length} reports{' '}
                        <ClearReportsButton
                           user={user}
                           onCleared={async () => {
                              await new Promise((resolve) => setTimeout(resolve, 5000));
                              refetch();
                           }}
                        />
                     </Typography>
                     <ReportsTable reports={user.reports!} />
                  </>
               )}

               {tabviewValue === 'Logs' && (
                  <>
                     <Typography mt={2} mb={2} variant='h4'>
                        Logs
                     </Typography>
                     <UserLogsTable logs={user.logs!} />
                  </>
               )}
            </Grid>
         </Grid>
      </div>
   );
};

export default ViewUser;

import { Check } from '@mui/icons-material';
import {
   Button,
   Card,
   CardContent,
   CardHeader,
   Chip,
   CircularProgress,
   MenuItem,
   Select,
   TextField,
   ToggleButton,
   ToggleButtonGroup,
   Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import {
   GameType,
   Locales,
   SavePromptInput,
   TagPrefillType,
   useSavePromptMutation,
} from '../../../../shared/utilities/__generated__/graphql';
import { stringToColor } from '../../../../shared/utilities/helpers';
import { buildGameTypeChip, buildPrefillTypeChip } from '../AllPrompts/AllPrompts';
import { CHAT_TYPES } from '../EditPrompt/EditPrompt';

const NewPrompt = () => {
   const [sharedSettings, setSharedSettings] = useState({
      chatTypes: [] as string[],
      tagPrefillType: TagPrefillType.NONE,
      gameType: GameType.VOICE_CHALLENGE,
   });

   const [prompts, setPrompts] = useState({} as any); // Locales to SavePropmtInput

   const { enqueueSnackbar } = useSnackbar();
   const [savePromptMutation] = useSavePromptMutation();
   const [isSaving, setIsSaving] = useState(false);

   // Available locales
   const LOCALES = [Locales.DA, Locales.EN, Locales.NO, Locales.SV, Locales.NL];

   const updateSharedSettings = (field: string, value: any) => {
      setSharedSettings((prev) => ({
         ...prev,
         [field]: value,
      }));

      setPrompts((prev: any) => {
         const updated: any = {};
         Object.keys(prev).forEach((locale) => {
            updated[locale] = {
               ...prev[locale],
               ...sharedSettings,
               [field]: value,
            };
         });
         return updated;
      });
   };

   const updatePromptContent = (locale: string, content: string) => {
      setPrompts((prev: any) => ({
         ...prev,
         [locale]: {
            ...sharedSettings,
            content,
            locale,
         },
      }));
   };

   return (
      <div style={{ padding: '24px' }}>
         {/* Shared Settings */}
         <Card style={{ marginBottom: '24px' }}>
            <CardHeader title='Shared Settings' />
            <CardContent>
               {/* Chat Types */}
               <div style={{ marginBottom: '20px' }}>
                  <Typography variant='body2' style={{ paddingBottom: 12, fontWeight: 800 }}>
                     Chat Types
                  </Typography>
                  <ToggleButtonGroup>
                     {Object.values(CHAT_TYPES).map((t: string) => {
                        const hasTypeEnabled = sharedSettings.chatTypes.includes(t);
                        return (
                           <ToggleButton
                              key={t}
                              selected={hasTypeEnabled}
                              color='info'
                              value='all'
                              onClick={() => {
                                 const newTypes = hasTypeEnabled
                                    ? sharedSettings.chatTypes.filter((type) => type !== t)
                                    : [...sharedSettings.chatTypes, t];
                                 updateSharedSettings('chatTypes', newTypes);
                              }}
                           >
                              {t}
                              {hasTypeEnabled ? <Check /> : undefined}
                           </ToggleButton>
                        );
                     })}
                  </ToggleButtonGroup>
               </div>

               {/* Prefill Type */}
               <div style={{ marginBottom: '20px' }}>
                  <Typography variant='body2' style={{ paddingBottom: 12, fontWeight: 800 }}>
                     Tag prefill
                  </Typography>
                  <Select
                     fullWidth
                     value={sharedSettings.tagPrefillType}
                     onChange={(e) => updateSharedSettings('tagPrefillType', e.target.value)}
                  >
                     {Object.values(TagPrefillType).map((prefillType) => (
                        <MenuItem value={prefillType} key={prefillType}>
                           {buildPrefillTypeChip(prefillType)}
                        </MenuItem>
                     ))}
                  </Select>
               </div>

               {/* Game Type */}
               <div style={{ marginBottom: '20px' }}>
                  <Typography variant='body2' style={{ paddingBottom: 12, fontWeight: 800 }}>
                     Game type
                  </Typography>
                  <Select
                     fullWidth
                     value={sharedSettings.gameType}
                     onChange={(e) => updateSharedSettings('gameType', e.target.value)}
                  >
                     {Object.values(GameType).map((gameType) => (
                        <MenuItem value={gameType} key={gameType}>
                           {buildGameTypeChip(gameType)}
                        </MenuItem>
                     ))}
                  </Select>
               </div>
            </CardContent>
         </Card>

         {/* Language-specific Prompts */}
         <div style={{ display: 'grid', gap: '20px' }}>
            {LOCALES.map((locale) => (
               <Card key={locale}>
                  <CardHeader
                     title={
                        <Chip
                           sx={{ backgroundColor: stringToColor(locale) }}
                           color='info'
                           label={locale}
                        />
                     }
                  />
                  <CardContent>
                     <TextField
                        fullWidth
                        multiline
                        rows={4}
                        value={prompts[locale]?.content || ''}
                        onChange={(e) => updatePromptContent(locale, e.target.value)}
                        placeholder={`Enter prompt content for ${locale}`}
                     />
                  </CardContent>
               </Card>
            ))}
         </div>

         {/* Save Button */}
         <div style={{ marginTop: '24px' }}>
            <Button
               variant='contained'
               color='primary'
               disabled={
                  isSaving ||
                  Object.values(prompts).length < LOCALES.length ||
                  Object.values(prompts).some((p: any) => !p?.content) ||
                  Object.values(prompts).some((p: any) => !p?.chatTypes?.length)
               }
               onClick={async () => {
                  enqueueSnackbar('Saving prompts...', { variant: 'info' });
                  setIsSaving(true);
                  const id = uuidv4();
                  const inputs = Object.entries(prompts).map(([locale, promptInput]) => ({
                     ...(promptInput as SavePromptInput),
                     locale,
                     id,
                  })) as SavePromptInput[];

                  try {
                     await Promise.all([
                        ...inputs.map((input) =>
                           savePromptMutation({ variables: { input: input } }),
                        ),
                     ]);
                     enqueueSnackbar('Prompts saved successfully', { variant: 'success' });
                     enqueueSnackbar('The ID of the prompt is ' + id, { variant: 'success' });
                  } catch (e) {
                     enqueueSnackbar('Failed to save some prompts', { variant: 'error' });
                     enqueueSnackbar(
                        'Btw idk if some went through, so you better check the db to make sure',
                        { variant: 'error' },
                     );
                     enqueueSnackbar('The ID of the prompt is ' + id, { variant: 'error' });
                     console.error(e);
                  } finally {
                     setIsSaving(false);
                     for (const locale of LOCALES) {
                        updatePromptContent(locale, '');
                     }
                     window.scrollTo(0, 0);
                  }
               }}
            >
               Save All Prompts
            </Button>
            {isSaving ? <CircularProgress size={20} /> : ''}
         </div>
      </div>
   );
};

export default NewPrompt;
